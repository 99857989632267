
const router_modular = [
    {
        path: '/',
        name: 'index',//首页0.0
        component: () => import('@/views/index/index')
    },
    {
        path: '/login',
        name: 'login',//登陆页面1.0 /union/login
        component: () => import('@/views/loginView/loginView')
    },
    {
        path: '/verifyName',
        name: 'verifyName',//实名认证2.0 /user/limit
        component: () => import('@/views/verifyName/verifyName'),
        meta:{
            keepAlive:true
        }
    },
    {
        path: '/verifyNameFail',
        name: 'verifyNameFail',
        component: () => import('@/views/verifyName/verifyNameFail'),
    },
    {
        path: '/faceVerify',
        name: 'startFaceVerify',//人脸识别3.0
        component: () => import('@/views/faceVerify/startVerify')
    },
    {
        path: '/faceVerify/recordVideo',
        name: 'recordVideo',//人脸识别3.1
        component: () => import('@/views/faceVerify/recordVideo')
    },
    {
        path: '/infoForm',
        name: 'infoForm',//完善个人信息4.0
        component: () => import('@/views/infoForm/infoForm')
    },
    {
        path: '/borrowMoney/index',
        name: 'borrowMoney',//借款金额5.0
        component: () => import('@/views/borrowMoney/index'),
        meta:{
            keepAlive:true
        }
    },
    {
        path: '/borrowMoney/repayPlan',
        name: 'repayPlan',//还款计划5.6
        component: () => import('@/views/borrowMoney/repayPlan')
    },
    {
        path: '/borrowMoney/applySuccess',
        name: 'applySuccess',//申请成功6.2
        component: () => import('@/views/borrowMoney/applySuccess')
    },
    {
        path: '/borrowMoney/applyFail',
        name: 'applyFail',//申请失败6.1
        component: () => import('@/views/borrowMoney/applyFail')
    },
    {
        path: '/borrowMoney/applySubmitSuccess',
        name: 'applySubmitSuccess',//提交成功6.0
        component: () => import('@/views/borrowMoney/applySubmitSuccess')
    },
    {
        path: '/contractRead/mustRead',
        name: 'mustRead',
        component: () => import('@/views/contractRead/mustRead')
    },
    {
        path: '/contractRead/normalRead',
        name: 'normalRead',
        component: () => import('@/views/contractRead/normalRead')
    },
    {
        path: '/contractRead/privacyContract',
        name: 'privacyContract',
        component: () => import('@/views/contractRead/privacyContract')
    },
    {
        path: '/contractRead/sensitiveContract',
        name: 'sensitiveContract',
        component: () => import('@/views/contractRead/sensitiveContract')
    },
    {
        path: '/contractRead/faceContract',
        name: 'faceContract',
        component: () => import('@/views/contractRead/faceContract')
    },
    
]
export default router_modular