/* eslint-disable */
// 根据navigator.userAgent判断一下当前环境属于ios环境还是按住哦环境
let isAndroid =
  navigator.userAgent.indexOf("Android") > -1 ||
  navigator.userAgent.indexOf("Adr") > -1;
let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

/** 针对安卓和ios系统，对window.WebViewJavascriptBridge进行兼容性处理 */
//这是必须要写的，用来创建一些设置
function setupWebViewJavascriptBridge(callback) {
  //Android使用
  if (isAndroid) {
    if (window.WebViewJavascriptBridge) {
      callback(WebViewJavascriptBridge);
    } else {
      //添加dom事件
      document.addEventListener(
        "WebViewJavascriptBridgeReady",
        function() {
          callback(WebViewJavascriptBridge);
        },
        false
      );
    }
    sessionStorage.phoneType = "android";
  }

  //iOS使用
  if (isiOS) {
    if (window.WebViewJavascriptBridge) {
      return callback(WebViewJavascriptBridge);
    }
    if (window.WVJBCallbacks) {
      return window.WVJBCallbacks.push(callback);
    }
    window.WVJBCallbacks = [callback];
    var WVJBIframe = document.createElement("iframe");
    WVJBIframe.style.display = "none";
    // WVJBIframe.src = "wvjbscheme://__BRIDGE_LOADED__";
    WVJBIframe.src = 'https://__bridge_loaded__';
    document.documentElement.appendChild(WVJBIframe);
    setTimeout(function() {
      document.documentElement.removeChild(WVJBIframe);
    }, 0);
    sessionStorage.phoneType = "ios";
  }
}
//注册回调函数，第一次连接时调用 初始化函数(android需要初始化,ios不用)
setupWebViewJavascriptBridge(function(bridge) {
  if (isAndroid) {
    //初始化
    bridge.init(function(message, responseCallback) {
      var data = {
        "Javascript Responds": "Wee!"
      };
      responseCallback(data);
    });
  }
});

export default {
  // js调APP方法 （参数分别为:app提供的方法名  传给app的数据  回调）
  callHandler(name, data, callback) {
    setupWebViewJavascriptBridge(function(bridge) {
      bridge.callHandler(name, data, callback);
    });
  },
  // APP调js方法 （参数分别为:js提供的方法名  回调）
  registerHandler(name, callback) {
    setupWebViewJavascriptBridge(function(bridge) {
      bridge.registerHandler(name, function(data, responseCallback) {
        callback(data, responseCallback);
      });
    });
  },
  isAndroid() {
    return isAndroid;
  },
  isIOS() {
    return isiOS;
  }
};

