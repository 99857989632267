/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import router_modular from '@/router/router_modular/index.js'


Vue.use(VueRouter)

//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const routes = [
  ...router_modular
]

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  base:"/xrp/",
  routes,
  mode: 'history',
  // ignoreRoutes:[/^\/api($|\/)/]
})
//全局路由守卫
router.beforeEach((to,from,next)=>{
  // document.title = config.title
  next()
})
router.afterEach((to,from)=>{
  // console.log(to,from);
})

// $router.onError((error) => {
//   const pattern = /Loading chunk (\d)+ failed/g;
//   const isChunkLoadFailed = error.message.match(pattern);
//   if(isChunkLoadFailed){
//     // 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
//       location.reload();
//       // const targetPath = $router.history.pending.fullPath;
//       // $router.replace(targetPath);
//   }
  
// });
export default router
