
import { getToken, setToken, removeToken } from '@/utils/auth'

const getDefaultState = () => {
    return {
      token: getToken(),
    }
  }
  
const state = getDefaultState()
const actions = {
    login({commit},data){
        commit('SET_TOKEN', data) 
        setToken(data)
    }
}
const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
  }
  
