<template>
    <div v-if="show" class="loading-box">
      <van-overlay class="box" :show="true">
        <div class="wrapper" @click.stop>
          <van-loading class="block" size="50"  type="spinner" color="#C19564" >加载中...</van-loading>
        </div>
      </van-overlay>
    </div>
  </template>
  <script>
  //this.$loading.show();
  //this.$loading.hide();
  export default {
    name: "loading",
    props: {
      show: Boolean,
    },
  };
  </script>
  
  <style lang="less" scoped>
  .box {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999;
    // background-color: rgba(255,255,255,.9);
    background-color: #fff;
    .van-loading__text{
        font-size: 30px;
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    //   .block {
    //     color: #6a72fe;
    //   }
    }
  }
  </style>
  