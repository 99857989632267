import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible';
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
import loading from "./views/loading/loading.js";

import Bridge from '@/utils/bridge'
import Vconsole from 'vconsole'
 
if(process.env.VUE_APP_RUN_ENV == 'uat' || process.env.VUE_APP_RUN_ENV == "development"){
  let vConsole = new Vconsole()
  Vue.use(vConsole);
}


Vue.prototype.$bridge = Bridge

Vue.use(Vant);
Vue.use(loading);

Vue.config.productionTip = false

new Vue({
  axios,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
